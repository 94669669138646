export const userProfileConstants = {
  FIRSTNAME: 'FIRST_NAME',
  LASTNAME: 'LAST_NAME',
  CONTACT: 'CONTACT',
  PROFILEIMAGE: 'PROFILE_IMAGE',
  TOKEN: 'TOKEN',
  USERNAME: 'USERNAME',
  LOGOUT: 'LOGOUT',
  EMAIL: 'PROFILE_EMAIL',
  COUNTRYCODE: 'PROFILE_COUNTRY_CODE',
  BIRTHDAY: 'PROFILE_BIRTHDAY_HAI',
  ADDRESS: 'PROFILE_ADDRESS_MILEGA',
  GENDER: 'PROFILE_GENDER',
};
