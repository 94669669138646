import { testConstants } from '../../constants';

function setTestIdToStore(payload) {
  return { type: testConstants.TESTID, payload };
}

function setTestTypeToStore(payload) {
  return { type: testConstants.TESTTYPE, payload };
}

function setTestStartTimeToStore(payload) {
  return { type: testConstants.TESTSTARTTIME, payload };
}

function setTestEndTimeToStore(payload) {
  return { type: testConstants.TESTENDTIME, payload };
}

function setTestResultArrayToStore(payload) {
  return { type: testConstants.RESULTARRAY, payload };
}

function setTestLanguageToStore(payload) {
  return { type: testConstants.TESTLANGUAGE, payload };
}

function setTestAnalysisTimeToStore(payload) {
  return { type: testConstants.ANALYSISTIME, payload };
}

function setFileUrlToStore(payload) {
  return { type: testConstants.FILEURL, payload };
}

function clearTests() {
  return { type: testConstants.CLEARTESTS };
}

export const testsActions = {
  setTestIdToStore,
  setTestTypeToStore,
  setTestStartTimeToStore,
  setTestEndTimeToStore,
  setTestResultArrayToStore,
  clearTests,
  setTestLanguageToStore,
  setFileUrlToStore,
  setTestAnalysisTimeToStore,
};
