export const dashboardConstants = {
  DASHBOARDDATA: 'DASHBOARD_DATA',
  CLEARDASHBOARDDATA: 'CLEAR_DASHBOARD_DATA',
  REDIRECTPATH: 'DASHBOARD_REDIRECT_PATH',
  LOCATIONDATA: 'LOCATIONDATA',
  SCROLLEDHEIGHT: 'SCROLLEDHEIGHT',
  FIREBASETOKEN: 'FIREBASETOKEN',
  TOPICSARRAY: 'TOPICSARRAY',
  NAVBARDATA: 'NAVBARDATA',
};
