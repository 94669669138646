import React from 'react';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';

const ReOrderTile = ({ elem, ind }) => {
  return (
    <div>
      <div
        style={{
          margin: '10px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '5px',
          borderRadius: '5px',
          width: '100%',
          border: '1px solid #707070',
        }}
      >
        <span>{elem.feature_name}</span>
        <DragHandleRoundedIcon />
      </div>
    </div>
  );
};

export default ReOrderTile;
