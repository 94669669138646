export const testConstants = {
  TESTID: 'TEST_ID',
  TESTTYPE: 'TEST_TYPE',
  FILEURL: 'FILEURL',
  TESTSTARTTIME: 'TEST_START_TIME',
  TESTENDTIME: 'TEST_END_TIME',
  RESULTARRAY: 'RESULT_ARRAY',
  TESTLANGUAGE: 'TEST_LANGUAGE',
  CLEARTESTS: 'CLEAR_TESTS',
  ANALYSISTIME: 'ANALYSISTIME',
};
