/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns/format';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import compareAsc from 'date-fns/compareAsc';
import dashboardAssignmentImage from '../../assets/images/Dashboard/dashboardAssignment.svg';
import { testsActions } from '../../redux/actions/tests.action';
import LiveTestCounter from './LiveTestCounter';
import { get, apiValidation, post } from '../../Utilities';
import './Tests.scss';

const Test = (props) => {
  const {
    clientUserId,
    searchString,
    setTestResultArrayToStore,
    setTestEndTimeToStore,
    setTestStartTimeToStore,
    setTestTypeToStore,
    setTestIdToStore,
    setTestLanguageToStore,
    setTestAnalysisTimeToStore,
    setFileUrlToStore,
  } = props;
  const [liveTests, setLiveTests] = useState([]);
  const [demoTests, setDemoTests] = useState([]);
  const [allowLiveTest, setAllowLiveTest] = useState([]);

  const history = useHistory();

  useEffect(() => {
    let timer;
    if (searchString) {
      timer = setTimeout(() => {
        get({ client_user_id: clientUserId }, '/getAllTestOfStudent').then((res) => {
          const result = apiValidation(res);
          const [live, demo] = result.reduce(
            ([p, f], e) => (e.test_type === 'live test' ? [[...p, e], f] : [p, [...f, e]]),
            [[], []],
          );
          const liveData = live.filter((elem) => {
            return elem.test_name.toLowerCase().includes(searchString);
          });
          const demoData = demo.filter((elem) => {
            return elem.test_name.toLowerCase().includes(searchString);
          });
          setLiveTests(liveData);
          setDemoTests(demoData);
        });
      }, 500);
    } else {
      get({ client_user_id: clientUserId }, '/getAllTestOfStudent').then((res) => {
        const result = apiValidation(res);
        const [live, demo] = result.reduce(
          ([p, f], e) => (e.test_type === 'live test' ? [[...p, e], f] : [p, [...f, e]]),
          [[], []],
        );
        setLiveTests(live);
        setDemoTests(demo);
      });
    }
    // console.log(liveTests);
    return () => {
      clearTimeout(timer);
    };
  }, [clientUserId, searchString]);

  useEffect(() => {
    if (liveTests.length) {
      const falseArray = liveTests.map((e) => {
        const payload = {
          id: e.test_id,
          isAllowed: false,
          startTime: 0,
          endTime: 0,
        };
        return payload;
      });
      setAllowLiveTest(falseArray);
    }
  }, [liveTests]);

  const startLive = (
    responseArray,
    startTime = 0,
    endTime = 0,
    testType,
    testId,
    languageType = 'english',
    analysisTime,
  ) => {
    const { push } = history;
    setTestResultArrayToStore(responseArray);
    setTestEndTimeToStore(endTime);
    // setTestStartTimeToStore(startTime);
    setTestStartTimeToStore(Math.round(new Date().getTime() / 1000));
    setTestTypeToStore(testType);
    setTestIdToStore(testId);
    setTestAnalysisTimeToStore(analysisTime);
    setFileUrlToStore(analysisTime.file_url);
    setTestLanguageToStore(languageType);

    push('/questiontaker');
  };

  const isAllowed = useCallback(
    (bool, id, startTime, endTime) => {
      console.log(bool, startTime, endTime, id, allowLiveTest, 'sara kuchhh');
      if (allowLiveTest.length) {
        const newCheckLiveExpiryArray = allowLiveTest.map((elem) => {
          if (elem.id === id) {
            elem.isAllowed = bool;
            elem.startTime = startTime;
            elem.endTime = endTime;
          }
          return elem;
        });
        setAllowLiveTest(newCheckLiveExpiryArray);
      }
    },
    [allowLiveTest],
  );

  const startLiveTest = (elem) => {
    console.log(elem, allowLiveTest);

    const liveCheck = allowLiveTest.filter((e) => {
      return e.id === elem.test_id;
    });

    if (liveCheck.length > 0 && liveCheck[0].isAllowed) {
      const payload = {
        client_user_id: clientUserId,
        test_id: elem.test_id,
        language_type: elem.language_type,
      };

      get(
        payload,
        elem.file_url
          ? '/getEomrTestQuestionsForStudent'
          : '/getTestQuestionsForStudentWithLanguageLatest',
      ).then((res) => {
        Swal.fire({
          title: 'Your Live Test is Loaded',
          text: 'hello',
          icon: 'success',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: `Attempt`,
          denyButtonText: `Later`,
          customClass: 'Assignments__SweetAlert',
        }).then((result) => {
          if (result.isConfirmed) {
            const response = apiValidation(res);
            startLive(
              response,
              parseInt(liveCheck[0].startTime, 10),
              liveCheck[0].endTime,
              'livetest',
              elem.test_id,
              res.answer_key,
              elem,
            );
            console.log(response);
          }
        });
        console.log(res, 'live test');
      });
    }
  };

  const startDemoTest = (elem) => {
    const demoPayload = {
      test_id: elem.test_id,
      client_user_id: clientUserId,
    };
    console.log(elem);
    get(demoPayload, '/getDemoTestEndTime').then((res) => {
      const result = apiValidation(res);
      console.log(result, 'endTime');
      if (result.status === 'Not started') {
        Swal.fire({
          text: 'Do you wish to attempt the test?',
          icon: 'question',
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: `Attempt`,
          denyButtonText: `Later`,
          customClass: 'Assignments__SweetAlert',
        }).then((response) => {
          if (response.isConfirmed) {
            const testPayload = {
              client_user_id: clientUserId,
              test_id: elem.test_id,
              test_status: 'started',
            };

            post(testPayload, '/submitTest').then((testres) => {
              if (testres.success) {
                const demoTestPayload = {
                  client_user_id: clientUserId,
                  test_id: elem.test_id,
                  language_type: elem.language_type,
                };
                get(
                  demoTestPayload,
                  elem.file_url
                    ? '/getEomrTestQuestionsForStudent'
                    : '/getTestQuestionsForStudentWithLanguageLatest',
                ).then((r) => {
                  console.log(r, 'r');
                  const studentQuestions = apiValidation(r);
                  console.log(
                    +new Date(),
                    +new Date() + parseInt(result.duration, 10) / 1000,
                    'wtfffff',
                  );
                  startLive(
                    studentQuestions,
                    Math.round(+new Date() / 1000),
                    Math.round((+new Date() + parseInt(result.duration, 10)) / 1000),
                    'demotest',
                    elem.test_id,
                    null,
                    elem,
                  );
                });
              }
            });
          } else if (response.isDenied) {
            console.log('oh no');
          }
        });
      } else if (result.status === 'started') {
        const currentTime = fromUnixTime(result.current_time);
        const testStartTime = fromUnixTime(result.test_end_time);
        const dateResult = compareAsc(currentTime, testStartTime);
        console.log(dateResult);

        if (dateResult < 0) {
          const demoTestPayload = {
            client_user_id: clientUserId,
            test_id: elem.test_id,
            language_type: elem.language_type,
          };
          get(
            demoTestPayload,
            elem.file_url
              ? '/getEomrTestQuestionsForStudent'
              : '/getTestQuestionsForStudentWithLanguageLatest',
          ).then((response) => {
            console.log(response);
            const studentQuestions = apiValidation(response);
            startLive(
              studentQuestions,
              result.current_time,
              result.test_end_time,
              'demotest',
              elem.test_id,
              elem.language_type,
              elem,
            );
          });
        } else if (dateResult > 0) {
          const testPayload = {
            client_user_id: clientUserId,
            test_id: elem.test_id,
            test_status: 'expired',
          };
          post(testPayload, '/submitTest').then((responseSubmit) => {
            if (responseSubmit.success) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'The test has expired',
              });
            }
          });
        }
      } else if (result.status === 'expired') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'The test has expired',
        });
      }
    });
  };

  if (!liveTests.length && !demoTests.length) {
    console.log(liveTests.length);
    return (
      <div className='noMatch'>
        <bold>No test found</bold>
      </div>
    );
  }

  return (
    <div>
      {liveTests.length > 0 && allowLiveTest.length > 0 && (
        <section className='Tests__scrollableCard divContainer'>
          {liveTests.map((elem) => {
            return (
              <div
                key={elem.test_id}
                className='ml-2 secContainer'
                onClick={() => startLiveTest(elem)}
                onKeyDown={() => startLiveTest(elem)}
                tabIndex='-1'
                role='button'
              >
                <Row>
                  <Col xs={9} className='pr-0'>
                    <p className='Tests__scrollableCardHeading pt-2 pl-3 mb-0'>
                      <span style={{ color: 'rgba(255, 0, 0, 0.87)' }}>Live</span>{' '}
                      <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Test</span>
                    </p>
                    <p className='Tests__scrollableCardText pl-3 mt-1'>{elem.test_name}</p>
                    <LiveTestCounter id={elem.test_id} isAllowed={isAllowed} />
                  </Col>
                  <Col xs={3} className='pt-3 px-0 livetestImg'>
                    <img
                      src={dashboardAssignmentImage}
                      alt='assignment'
                      height='63px'
                      width='57px'
                      style={{
                        filter:
                          'invert(16%) sepia(88%) saturate(7487%) hue-rotate(2deg) brightness(95%) contrast(117%)',
                      }}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
        </section>
      )}
      {demoTests.length > 0 && (
        <section className='Tests__scrollableCard divContainer'>
          {demoTests.map((elem) => {
            return (
              <div
                key={elem.test_id}
                className='ml-2 secContainer'
                onClick={() => startDemoTest(elem)}
                onKeyDown={() => startDemoTest(elem)}
                tabIndex='-1'
                role='button'
              >
                <Row>
                  <Col xs={9} className='pr-0'>
                    <p className='Tests__scrollableCardHeading pt-2 pl-3 mb-0'>
                      <span style={{ color: 'rgba(207, 236, 0, 0.87)' }}>Mock</span>{' '}
                      <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>Test</span>
                    </p>
                    <p className='Tests__scrollableCardText pl-3 mt-1 mb-0'>{elem.test_name}</p>
                    <p className='Tests__scrollableCardText pl-3 mt-1'>
                      Due:{' '}
                      <span className='Tests__Counter'>
                        {format(fromUnixTime(elem.due_date), 'MMM dd, yyyy')}
                      </span>
                    </p>
                  </Col>
                  <Col xs={3} className='pt-3 px-0 livetestImg'>
                    <img
                      src={dashboardAssignmentImage}
                      alt='assignment'
                      height='63px'
                      width='57px'
                      style={{
                        filter:
                          'invert(79%) sepia(86%) saturate(518%) hue-rotate(10deg) brightness(95%) contrast(102%)',
                      }}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
        </section>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTestIdToStore: (payload) => {
    dispatch(testsActions.setTestIdToStore(payload));
  },
  setTestTypeToStore: (payload) => {
    dispatch(testsActions.setTestTypeToStore(payload));
  },
  setTestStartTimeToStore: (payload) => {
    dispatch(testsActions.setTestStartTimeToStore(payload));
  },
  setTestEndTimeToStore: (payload) => {
    dispatch(testsActions.setTestEndTimeToStore(payload));
  },
  setTestResultArrayToStore: (payload) => {
    dispatch(testsActions.setTestResultArrayToStore(payload));
  },
  setTestLanguageToStore: (payload) => {
    dispatch(testsActions.setTestLanguageToStore(payload));
  },
  setTestAnalysisTimeToStore: (payload) => {
    dispatch(testsActions.setTestAnalysisTimeToStore(payload));
  },
  setFileUrlToStore: (payload) => {
    dispatch(testsActions.setFileUrlToStore(payload));
  },
});

export default connect(null, mapDispatchToProps)(Test);

Test.propTypes = {
  clientUserId: PropTypes.number.isRequired,
  searchString: PropTypes.string.isRequired,
  setTestIdToStore: PropTypes.func.isRequired,
  setTestTypeToStore: PropTypes.func.isRequired,
  setTestStartTimeToStore: PropTypes.func.isRequired,
  setTestEndTimeToStore: PropTypes.func.isRequired,
  setTestResultArrayToStore: PropTypes.func.isRequired,
  setTestAnalysisTimeToStore: PropTypes.func.isRequired,
  setTestLanguageToStore: PropTypes.func.isRequired,
  setFileUrlToStore: PropTypes.func.isRequired,
};
