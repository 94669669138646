export const courseConstants = {
  COURSEID: 'COURSE_ID',
  COURSEOBJECT: 'COURSE_OBJECT',
  COURSECURRENTSLIDE: 'COURSE_CURRENT_SLIDE',
  COURSESECTIONNAME: 'COURSE_SECTION_NAME',
  COURSESECTIONID: 'COURSE_SECTION_ID',
  COURSESECTIONPRIORITYORDER: 'COURSE_SECTION_PRIORITY_ORDER',
  COURSEADDCONTENTTESTID: 'COURSE_ADD_CONTENT_TEST_ID',
  COURSENOWPLAYINGVIDEO: 'COURSENOWPLAYINGVIDEO',
  COURSEDOCUMENTTOOPEN: 'COURSEDOCUMENTTOOPEN',
};
