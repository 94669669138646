export const conversationsConstants = {
  CONNECTION: 'CONNECTION',
  SET_SOCKET: 'SET_SOCKET',
  CONVERSATIONS: 'CONVERSATIONS',
  CLEAR: 'CLEAR',
  SET_MESSAGES: 'SET_MESSAGES',
  SET_CONVERSATION: 'SET_CONVERSATION',
  SET_POST: 'SET_POST',
  SET_POSTS: 'SET_POSTS',
  SET_REPLIES: 'SET_REPLIES',
  GLOBALCOUNT: 'GLOBALCOUNT',
};
