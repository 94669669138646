export const homeworkConstants = {
  TESTID: 'HOMEWORK_TEST_ID',
  TESTNAME: 'HOMEWORK_TEST_NAME',
  QUESTIONARRAY: 'HOMEWORK_QUESTION_ARRAY',
  SELECTEDQUESTIONARRAY: 'HOMEWORK_SELECTED_QUESTION_ARRAY',
  CURRENTSLIDE: 'HOMEWORK_CURRENT_SLIDE',
  CLEARTESTS: 'HOMEWORK_CLEAR_TESTS',
  CURRENTCHAPTERARRAY: 'HOMEWORK_CURRENT_CHAPTER_ARRAY',
  CURRENTSUBJECTARRAY: 'HOMEWORK_CURRENT_SUBJECT_ARRAY',
  TESTISDRAFT: 'HOMEWORK_IS_DRAFT',
  TESTCLASSSUBJECT: 'HOMEWORK_CLASS_SUBJECT',
  HOMEWORKLANGUAGETYPE: 'HOMEWORK_LANGUAGE_TYPE',
  SELECTEDCOURSE: 'SELECTEDCOURSE',
  SELECTEDSUBJECT: 'SELECTEDSUBJECT',
  SELECTEDCHAPTER: 'SELECTEDCHAPTER',
  SELECTEDTYPE: 'SELECTEDTYPE',
  FILE_URL: 'FILE_URL',
};
