export const HEADER = {
  display: 'flex',
  width: '100%',
  padding: '10px',
  flexDirection: 'row',
  alignItems: 'center',
  background: 'white',
};
export const BACK_BUTTON = {
  marginRight: '20px',
};
export const TITLES = {
  fontSize: '22px',
};
