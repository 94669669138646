export const admissionConstants = {
  ADMISSIONUSERARRAY: 'ADMISSION_USER_ARRAY',
  ADMISSIONROLEARRAY: 'ADMISSION_ROLE_ARRAY',
  ADMISSIONPLANARRAY: 'ADMISSION_PLAN_ARRAY',
  ADMISSIONSUBJECTARRAY: 'ADMISSION_SUBJECT_ARRAY',
  ADMISSIONPLANTYPE: 'ADMISSION_PLAN_TYPE',
  ADMISSIONCLASSID: 'ADMISSION_CLASS_ID',
  ADMISSIONBATCHNAME: 'ADMISSION_BATCH_NAME',
  ADMISSIONBATCHDESCRIPTION: 'ADMISSION_BATCH_DESCRIPTION',
  ADMISSIONBATCHDATE: 'ADMISSION_BATCH_DATE',
  ADMISSIONUSERPROFILE: 'ADMISSION_USER_PROFILE',
};
