import { testConstants } from '../../constants';

const initialState = {
  testId: null,
  testType: null,
  testStartTime: 0,
  testEndTime: 0,
  testresultArray: [],
  testLanguage: 'english',
  testAnalysisTime: 0,
  fileUrl: null,
};

export function testsUpdate(state = initialState, action) {
  switch (action.type) {
    case testConstants.TESTID:
      return {
        ...state,
        testId: action.payload,
      };

    case testConstants.TESTTYPE:
      return {
        ...state,
        testType: action.payload,
      };
    case testConstants.TESTSTARTTIME:
      return {
        ...state,
        testStartTime: action.payload,
      };
    case testConstants.TESTENDTIME:
      return {
        ...state,
        testEndTime: action.payload,
      };

    case testConstants.RESULTARRAY:
      return {
        ...state,
        testresultArray: action.payload,
      };
    case testConstants.TESTLANGUAGE:
      return {
        ...state,
        testLanguage: action.payload,
      };
    case testConstants.FILEURL:
      return {
        ...state,
        fileUrl: action.payload,
      };
    case testConstants.ANALYSISTIME:
      return {
        ...state,
        testAnalysisTime: action.payload,
      };
    case testConstants.CLEARTESTS:
      return {
        ...state,
        testId: null,
        testType: null,
        testStartTime: 0,
        testEndTime: 0,
        testresultArray: [],
        testLanguage: 'english',
        fileUrl: null,
      };
    default:
      return state;
  }
}

export const getTestId = (state) => state.testsUpdate.testId;
export const gettestType = (state) => state.testsUpdate.testType;
export const getTestStartTime = (state) => state.testsUpdate.testStartTime;
export const getTestResultArray = (state) => state.testsUpdate.testresultArray;
export const getTestEndTime = (state) => state.testsUpdate.testEndTime;
export const getTestLanguage = (state) => state.testsUpdate.testLanguage;
export const getTestAnalysisTime = (state) => state.testsUpdate.testAnalysisTime;
export const getFileUrlOfTest = (state) => state.testsUpdate.fileUrl;
