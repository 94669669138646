import React, { useEffect } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';
import axios from 'axios';
import { connect } from 'react-redux';
import { BACK_BUTTON, HEADER, TITLES } from './styles';
import { dashboardActions } from '../../redux/actions/dashboard.action';
import { get, post } from '../../Utilities';
import ReOrderTile from './ReOrderTile';
import {
  getClientId,
  getClientUserId,
  getRoleArray,
} from '../../redux/reducers/clientUserId.reducer';
import { getNavbarData } from '../../redux/reducers/dashboard.reducer';

const ReOrderTabs = ({
  clientId,
  clientUserId,
  roleArray,
  getNavbarData,
  setNavbarDataToStore,
}) => {
  const [featureList, setFeatureList] = React.useState([]);
  const roleId = roleArray.includes(5)
    ? 5
    : roleArray.includes(4)
    ? 4
    : roleArray.includes(3)
    ? 3
    : roleArray.includes(2)
    ? 2
    : 1;

  useEffect(() => {
    get({ client_id: clientId, role_id: roleId }, '/getClientFeature2').then((res) => {
      console.log(res);
      setFeatureList(
        res?.result
          ?.filter(
            (elem) =>
              elem.feature_name !== 'aboutUs' &&
              elem.feature_name !== 'contactUs' &&
              elem.feature_name !== 'connectWithUs' &&
              elem.feature_name !== 'share' &&
              elem.feature_name !== 'posters' &&
              elem.feature_name !== 'videos' &&
              elem.feature_name !== 'starPerformers' &&
              elem.feature_name !== 'testimonial' &&
              elem.feature_name !== 'admissionForm' &&
              elem.feature_name !== 'admission' &&
              elem.feature_name !== 'onlineAssignment' &&
              elem.feature_name !== 'myCourses',
          )
          .reduce(
            (acc, elem) => {
              if (!acc.seenFeatureIds.has(elem.feature_id)) {
                acc.seenFeatureIds.add(elem.feature_id);
                acc.filteredList.push(elem);
              }
              return acc;
            },
            { seenFeatureIds: new Set(), filteredList: [] },
          )
          .filteredList.map((elem, index) => ({ ...elem, id: index })),
      );
    });
  }, []);

  useEffect(() => {
    setNavbarDataToStore(featureList);
  }, [featureList]);

  console.log(featureList);
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  useEffect(() => {
    console.log(featureList, 'PriyamBhaiya');
    // const feature_array = JSON.stringify(featureList);
    // post(
    //   {
    //     feature_array,
    //     role_id: 4,
    //     client_id: clientId,
    //   },
    //   '/modifyFeaturesOfClient2',
    // ).then((res) => {
    //   console.log(res);
    // });
  }, [featureList]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = Array.from(featureList);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    reorderedItems.forEach((item, i) => {
      item.order_id = i + 1;
      item.order = i + 1;
    });
    setFeatureList(reorderedItems);

    const feature_array = JSON.stringify(reorderedItems);
    console.log(reorderedItems);

    post(
      {
        feature_array,
        role_id: 4,
        client_id: clientId,
      },
      '/modifyFeaturesOfClient2',
    ).then((res) => {
      console.log(res);
    });
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <div
            style={{
              ...HEADER,
              position: 'sticky',
              height: '64px',
              top: 0,
              zIndex: 1000,
              fontFamily: 'Montserrat-Regular',
              boxShadow:
                '0px 1.85px 6.25px 0px rgba(0, 0, 0, 0.19), 0px 0.5px 1.75px 0px rgba(0, 0, 0, 0.04)',
            }}
          >
            <ArrowBack onClick={goBack} style={BACK_BUTTON} />
            <div style={TITLES}>Reorder tabs</div>
          </div>
          <Droppable droppableId='bottomNavigation'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  fontSize: '15px',
                  fontFamily: 'Montserrat-Regular',
                  fontWeight: '600',
                  fontStyle: 'normal',
                  width: '100%',
                  padding: '20px',
                  margin: '20px auto',
                }}
              >
                Bottom Navigation{' '}
                <span
                  style={{ fontSize: '10px', color: '#0000008A', fontFamily: 'Montserrat-Regular' }}
                >
                  (Maximum 3)
                </span>
                {featureList
                  .slice(0, 3)

                  .map((elem, index) => (
                    <Draggable key={elem.id} draggableId={elem.id.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ReOrderTile ind={index} elem={elem} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId='moreOptions'>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  fontSize: '15px',
                  fontFamily: 'Montserrat-Regular',
                  fontWeight: '600',
                  fontStyle: 'normal',
                  width: '100%',
                  padding: '20px',
                  margin: '10px auto 40px auto',
                }}
              >
                More
                {featureList
                  .slice(4)
                  // .filter(
                  //   (elem) =>
                  //     elem.feature_name !== 'aboutUs' &&
                  //     elem.feature_name !== 'contactUs' &&
                  //     elem.feature_name !== 'connectWithUs' &&
                  //     elem.feature_name !== 'share' &&
                  //     elem.feature_name !== 'posters' &&
                  //     elem.feature_name !== 'videos' &&
                  //     elem.feature_name !== 'starPerformers' &&
                  //     elem.feature_name !== 'testimonial' &&
                  //     elem.feature_name !== 'admissionForm' &&
                  //     elem.feature_name !== 'admission' &&
                  //     elem.feature_name !== 'onlineAssignment',
                  // )
                  .map((elem, index) => (
                    <Draggable key={elem.id} draggableId={elem.id.toString()} index={index + 4}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ReOrderTile ind={index + 4} elem={elem} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};
const mapStateToProps = (state) => ({
  clientId: getClientId(state),
  clientUserId: getClientUserId(state),
  roleArray: getRoleArray(state),
});

const mapDispatchToProps = (dispatch) => ({
  setNavbarDataToStore: (payload) => {
    dispatch(dashboardActions.setNavbarDataToStore(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReOrderTabs);
